<template>
    <div :class="isDefaultClose ? 'margin-bottom-20' : 'margin-bottom-50'">
        <div
            class="content-viewtitle"
            :class="{ active: isToggle }"
            @click="
                isToggle = $isMobile() && !isDefaultClose ? true : !isToggle
            "
        >
            影像檔
            <div
                v-if="$isMobile() === false || isShowModal"
                class="toggle-menu-icon"
                :class="{ small: isShowModal }"
            />
        </div>
        <transition name="dropdown" mode="out-in">
            <div v-show="isToggle">
                <TextboxSearch
                    :isLoading="isLoading"
                    :placeholder="'搜尋影像檔關鍵字'"
                    :dataList="tableShowData"
                    :searchConfigs="searchConfigs"
                    @searched-data="setSearchedData"
                ></TextboxSearch>
                <Table
                    v-if="tableShowData.length > 0 || isLoading"
                    :isLoading="isLoading"
                    :columnConfig="columnConfig"
                    :rows="searchedData"
                >
                    <template v-slot:name="{ isHeader, row }">
                        <template v-if="!isHeader">
                            {{ row.name
                            }}{{ row.remark ? `(${row.remark})` : '' }}
                        </template>
                    </template>
                    <template v-slot:link="{ isHeader, row }">
                        <template v-if="!isHeader">
                            <a
                                v-if="row.link"
                                :href="row.link"
                                class="download-link-icon"
                                @click="getGa"
                            />
                            <span v-else class="no-download-link">無附件</span>
                        </template>
                    </template>
                </Table>
            </div>
        </transition>
    </div>
</template>

<script>
import TextboxSearch from '@/components/TextboxSearch.vue'
import Table from '@/components/Table.vue'
import {
    insuranceImageColumns,
    insuranceImageSearch
} from '@/assets/javascripts/tia/setting'

export default {
    name: 'InsuranceImage',
    components: {
        Table,
        TextboxSearch
    },
    props: {
        dataList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        isDefaultClose: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        isShowModal: {
            type: Boolean,
            default: function () {
                return null
            }
        }
    },
    methods: {
        getGa: function () {
            this.$setGaEvent('downloadAttached', 'click-Icon', {
                params: 'detail'
            })
        },
        setSearchedData(results) {
            this.searchedData = results
        }
    },
    computed: {
        tableShowData: function () {
            if (!this.dataList.insDocInfo) return []
            return this.dataList.insDocInfo
        },
        searchConfigs: function () {
            return insuranceImageSearch
        }
    },
    watch: {
        isShowModal: function () {
            this.isToggle = false
        },
        tableShowData: function () {
            // 設置初始data
            this.searchedData = this.tableShowData
        }
    },
    data() {
        return {
            columnConfig: insuranceImageColumns,
            isToggle: !this.isDefaultClose,
            searchedData: []
        }
    }
}
</script>

<style lang="scss" scoped>
.content-viewtitle {
    display: flex;
    align-items: center;
    padding: 0 0 10px 0;
    transition: margin 1s ease-in-out;
    color: $sixth-black;
    font-size: 24px;

    cursor: pointer;

    .toggle-menu-icon {
        height: 15px;
        width: 15px;
        margin-left: 15px;
        transition: transform 0.3s;
        transform: rotate(0deg);
        cursor: pointer;
        &.small {
            height: 10px;
            width: 10px;
        }
    }

    &.active {
        .toggle-menu-icon {
            transform: rotate(90deg);
        }
    }
    @media screen and (max-width: 576px) {
        padding: 0 0 10px 0;
        :deep(.main-header-word) {
            font-size: 20px;
            font-weight: 500;
        }
    }
}

:deep(.table-container) {
    margin-bottom: 0;
    padding-bottom: 10px;
}

.table-wrapper {
    :deep(.link) {
        width: 50px;
    }

    :deep(.table) {
        &.loading {
            .table-body {
                .row-container:nth-of-type(3) ~ .row-container {
                    display: none;
                }
            }
        }
    }

    :deep(.card) {
        @media screen and (max-width: 576px) {
            .row-container .row.body {
                .cell {
                    &.date {
                        padding-bottom: 10px;
                        border-bottom: 1px dashed $fourth-grey;
                    }
                    &.name {
                        width: calc(100% - 20px);
                        &:before {
                            width: 100%;
                            margin-bottom: 5px;
                        }
                    }
                    &.link {
                        width: 20px;
                        align-self: end;
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

:deep(.download-link-icon) {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

:deep(.no-download-link) {
    color: $ninth-grey;
}
</style>
